

































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import MemberComponent from "./Member.component";

@Component
export default class Member extends Mixins(MemberComponent) {}

