import { Component, Vue } from "vue-property-decorator";
import VueCookies from 'vue-cookies'
import MemberComponent from '../Component/Member.component.vue';
Vue.use(VueCookies)

@Component({
    components: {
        MemberComponent
    }
})
default class AddMember extends Vue {
   
}
export default AddMember