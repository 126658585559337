var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayShow
    ? _c("div", [
        _c("div", [
          _c("h1", [_vm._v(_vm._s(_vm.$t("MANAGEMENT.ADD_MEMBER")))]),
          _c("div", { staticClass: "section-group" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "form",
                  {
                    attrs: { lass: "forms-sample", id: "form-agent" },
                    on: {
                      submit: function($event) {
                        return _vm.sendForm($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "mb-4" }, [
                      _c("div", { staticStyle: { display: "inline-flex" } }, [
                        _c("h4", { staticClass: "bold" }, [
                          _vm._v(_vm._s(_vm.$t("MANAGEMENT.MEMBER_INFO")))
                        ])
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-4 col-form-label bold",
                                attrs: { for: "Username" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("MANAGEMENT.USERNAME")) +
                                    " "
                                ),
                                _c("span", { staticClass: "required" }, [
                                  _vm._v("★")
                                ])
                              ]
                            ),
                            _c("div", { staticClass: "col-8" }, [
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "Username",
                                  name: "Username",
                                  placeholder: _vm.$t("MANAGEMENT.USERNAME"),
                                  required: ""
                                },
                                domProps: { value: _vm.dataMember.username },
                                on: {
                                  keypress: function($event) {
                                    return _vm.clsAlphaNoOnly($event)
                                  },
                                  keyup: function($event) {
                                    return _vm.changeInputValue(
                                      "username",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c(
                                "small",
                                { staticClass: "text-muted form-text" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("MANAGEMENT.TEXT_NUMBER_ONLY")
                                    )
                                  )
                                ]
                              )
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-4 col-form-label bold",
                                attrs: { for: "Name" }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("MANAGEMENT.NAME")) + " "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "col-8" }, [
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "Name",
                                  name: "Name",
                                  placeholder: _vm.$t("MANAGEMENT.NAME")
                                },
                                domProps: { value: _vm.dataMember.name },
                                on: {
                                  keypress: function($event) {
                                    return _vm.clsAlphaNoOnly($event)
                                  },
                                  keyup: function($event) {
                                    return _vm.changeInputValue(
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c(
                                "small",
                                { staticClass: "text-muted form-text" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("MANAGEMENT.TEXT_NUMBER_ONLY")
                                    )
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-4 col-form-label bold",
                                attrs: { for: "Password" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("MANAGEMENT.PASSWORD")) +
                                    " "
                                ),
                                _c("span", { staticClass: "required" }, [
                                  _vm._v("★")
                                ])
                              ]
                            ),
                            _c("div", { staticClass: "col-8" }, [
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: _vm.password.default
                                    ? "text"
                                    : "password",
                                  id: "Password",
                                  name: "Password",
                                  placeholder: _vm.$t("MANAGEMENT.PASSWORD")
                                },
                                on: {
                                  keyup: function($event) {
                                    return _vm.changeInputValue(
                                      "password",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c(
                                "small",
                                { staticClass: "text-muted form-text" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("MANAGEMENT.PASSWORD_CHECK"))
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "toggle-password field-icon",
                                  attrs: { toggle: "#Password" }
                                },
                                [
                                  _c("i", {
                                    staticClass: "far fa-eye",
                                    on: {
                                      click: function($event) {
                                        return _vm.toggleShowPassword("default")
                                      }
                                    }
                                  })
                                ]
                              )
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-4 col-form-label bold",
                                attrs: { for: "ConfirmPassword" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("MANAGEMENT.CONFIRM_PASSWORD")
                                    ) +
                                    " "
                                ),
                                _c("span", { staticClass: "required" }, [
                                  _vm._v("★")
                                ])
                              ]
                            ),
                            _c("div", { staticClass: "col-8" }, [
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: _vm.password.confirm
                                    ? "text"
                                    : "password",
                                  id: "ConfirmPassword",
                                  name: "ConfirmPassword",
                                  placeholder: _vm.$t(
                                    "MANAGEMENT.CONFIRM_PASSWORD"
                                  )
                                },
                                on: {
                                  keyup: function($event) {
                                    return _vm.changeInputValue(
                                      "confirmPassword",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "toggle-password field-icon",
                                  attrs: { toggle: "#ConfirmPassword" }
                                },
                                [
                                  _c("i", {
                                    staticClass: "far fa-eye",
                                    on: {
                                      click: function($event) {
                                        return _vm.toggleShowPassword("confirm")
                                      }
                                    }
                                  })
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "mb-4" }, [
                      _c("h4", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.$t("MANAGEMENT.CONTACT_INFO")))
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-4 col-form-label bold",
                                attrs: { for: "Email" }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("MANAGEMENT.EMAIL")) + " "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "col-8" }, [
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "email",
                                  id: "Email",
                                  name: "Email",
                                  placeholder: _vm.$t("MANAGEMENT.EMAIL")
                                },
                                domProps: { value: _vm.dataMember.email },
                                on: {
                                  keyup: function($event) {
                                    return _vm.changeInputValue(
                                      "email",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-4 col-form-label bold",
                                attrs: { for: "Phone" }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("MANAGEMENT.PHONE")) + " "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "col-8" }, [
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "Phone",
                                  name: "Phone",
                                  placeholder: _vm.$t("MANAGEMENT.PHONE")
                                },
                                domProps: { value: _vm.dataMember.phone },
                                on: {
                                  keydown: function($event) {
                                    return _vm.onlyNumber($event)
                                  },
                                  keypress: function($event) {
                                    return _vm.onlyNumber($event)
                                  },
                                  keyup: function($event) {
                                    return _vm.changeInputValue(
                                      "phone",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c(
                                "small",
                                { staticClass: "text-muted form-text" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("MANAGEMENT.NUMBER_ONLY"))
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "mb-4" }, [
                      _c("h4", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.$t("MANAGEMENT.OTHER_INFO")))
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-4 col-form-label bold",
                                attrs: { for: "Referrer" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("MANAGEMENT.REFERRER")) +
                                    " "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "col-8" }, [
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "Referrer",
                                  placeholder: _vm.$t("MANAGEMENT.REFERRER")
                                },
                                domProps: { value: _vm.dataMember.referrer },
                                on: {
                                  keyup: function($event) {
                                    return _vm.changeInputValue(
                                      "referrer",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-4 col-form-label bold",
                                attrs: { for: "Remark" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("MANAGEMENT.REMARK")) +
                                    " "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "col-8" }, [
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "Remark",
                                  placeholder: _vm.$t("MANAGEMENT.REMARK")
                                },
                                domProps: { value: _vm.dataMember.remark },
                                on: {
                                  keyup: function($event) {
                                    return _vm.changeInputValue(
                                      "remark",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("div", { staticClass: "mb-4" }, [
                          _c("h4", { staticClass: "bold" }, [
                            _vm._v(_vm._s(_vm.$t("MANAGEMENT.SETTING_CREDIT")))
                          ]),
                          _vm._m(0)
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "col-4 col-form-label bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.CURRENCY")) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "required" }, [
                                    _vm._v("★")
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-8" },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      required: "",
                                      id: "currency",
                                      name: "currency",
                                      clearable: false,
                                      options: _vm.currencyArray,
                                      disabled:
                                        _vm.role == "Master Agent"
                                          ? true
                                          : false,
                                      placeholder: _vm.$t("MANAGEMENT.CURRENCY")
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.changeInputValue(
                                          "currency",
                                          _vm.dataMember.currency
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.dataMember.currency,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataMember,
                                          "currency",
                                          $$v
                                        )
                                      },
                                      expression: "dataMember.currency"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "col-4 col-form-label bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.CREDIT")) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "required" }, [
                                    _vm._v("★")
                                  ])
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    required: "",
                                    type: "text",
                                    id: "creditLimit",
                                    name: "creditLimit",
                                    placeholder: _vm.$t("MANAGEMENT.CREDIT")
                                  },
                                  domProps: {
                                    value: _vm.dataMember.creditLimit
                                  },
                                  on: {
                                    keydown: function($event) {
                                      return _vm.onlyNumber($event)
                                    },
                                    keypress: function($event) {
                                      return _vm.onlyNumber($event)
                                    },
                                    keyup: function($event) {
                                      return _vm.changeInputValue(
                                        "creditLimit",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c("label", {
                                staticClass: "col-4 col-form-label bold"
                              }),
                              _c("div", { staticClass: "col-8" }, [
                                _c(
                                  "label",
                                  { staticClass: "col-form-label bold" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("MANAGEMENT.MIN")) +
                                        " 0, " +
                                        _vm._s(_vm.$t("MANAGEMENT.MAX")) +
                                        " " +
                                        _vm._s(
                                          _vm._f("sCurrency")(
                                            _vm.profile.creditLimit
                                              ? _vm.profile.creditLimit
                                              : 0
                                          )
                                        )
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "col-4 col-form-label bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.OUR_PT")) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "required" }, [
                                    _vm._v("★")
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-8" },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      required: "",
                                      id: "myPercentage",
                                      name: "myPercentage",
                                      clearable: false,
                                      options: _vm.numberArray,
                                      placeholder: _vm.$t(
                                        "MANAGEMENT.ENTER_PERCENTAGE"
                                      )
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.changeInputValue(
                                          "myPercentage",
                                          _vm.dataMember.myPercentage
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.dataMember.myPercentage,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataMember,
                                          "myPercentage",
                                          $$v
                                        )
                                      },
                                      expression: "dataMember.myPercentage"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-sm-6" },
                        [
                          _c("div", { staticClass: "mb-4" }, [
                            _c("h4", { staticClass: "bold" }, [
                              _vm._v(_vm._s(_vm.$t("MANAGEMENT.LIMIT_SETTING")))
                            ]),
                            _vm._m(1)
                          ]),
                          _vm._l(_vm.limitList, function(t, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "row" },
                              [
                                _c("div", { staticClass: "col-sm-12" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c("label", {
                                      staticClass: "col-4 col-form-label bold"
                                    }),
                                    _c("div", { staticClass: "col-8" }, [
                                      _c("input", {
                                        staticClass: "form-check-input",
                                        attrs: {
                                          type: "radio",
                                          id: t.name,
                                          name: "limit",
                                          disabled:
                                            _vm.dataMember.myLimit >= t.value
                                              ? false
                                              : true
                                        },
                                        domProps: { checked: t.check },
                                        on: {
                                          click: function($event) {
                                            return _vm.changeInputValue(
                                              "limit",
                                              t.value
                                            )
                                          }
                                        }
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-check-label bold",
                                          attrs: { for: t.name }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(t.name) +
                                              " " +
                                              _vm._s(
                                                "" +
                                                  _vm.$t("MANAGEMENT.MIN") +
                                                  " = " +
                                                  t.min +
                                                  " , " +
                                                  _vm.$t("MANAGEMENT.MAX") +
                                                  " = " +
                                                  t.max +
                                                  ""
                                              ) +
                                              " "
                                          ),
                                          _c("i", {
                                            class:
                                              _vm.dataMember.limit >= t.value
                                                ? "fa fa-check text-success"
                                                : "fa fa-times text-danger"
                                          })
                                        ]
                                      )
                                    ])
                                  ])
                                ])
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _c("div"),
                    _c(
                      "div",
                      {
                        staticClass: "mb-4",
                        staticStyle: { "text-align": "center" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-yellow ripple-parent mr-2",
                            attrs: { type: "submit", id: "agent-submit" }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("MEMBER.BUTTON.ADD")) + " "
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "form-group row" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "form-group row" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }