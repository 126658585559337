var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                { attrs: { id: "'breadcrumb0", to: { name: "AddAgent" } } },
                [
                  _c("span", { attrs: { to: "" } }, [
                    _vm._v(_vm._s(_vm.$t("MANAGEMENT.MANAGEMENT")))
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                { attrs: { id: "'breadcrumb1", to: { name: "AgentList" } } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("MANAGEMENT.MEMBERS_AGENTS")))
                  ])
                ]
              )
            ],
            1
          ),
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("MANAGEMENT.ADD_MEMBER")))])
          ])
        ])
      ]),
      _c("MemberComponent")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }