























import { Component, Mixins } from "vue-property-decorator";
import AddMemberComponent from "./AddMember.component";
@Component
export default class AddMember extends Mixins(AddMemberComponent) {}
